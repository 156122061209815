import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 必须先引入umy-ui，再引入element-ui，否则会出现样式覆盖问题 ！！！
// 虚拟列表样式
import 'umy-ui/lib/theme-chalk/index.css';
// 引入elementUi
import ElementUI from 'element-ui'
import './assets/css/element-variables.scss'
Vue.use(ElementUI, {
    size: 'small',
    zIndex: 3000
})

import * as buffer from "buffer"; //引入buffer
 
if (typeof window.Buffer === "undefined") { // 判断当前环境是否有Buffer对象
   window.Buffer = buffer.Buffer; // Buffer对象不存在则创建导入的buffer
}


// 引入公共样式
import './assets/css/common.css';
import './assets/css/iconfont.css';
import "./assets/css/iconfont-new.css";
import './assets/css/wangeditor.scss';
import './assets/css/elementui-btn-reprovision.css';
import './assets/css/elementui-form-reprovision.scss';
import './assets/css/elementui-table-reprovision.scss';
import './assets/css/menu/iconfont.css';
import './assets/css/formDesign/iconfont.css';
import './assets/css/tableLine.scss';


// 引入主题左侧菜单图标
import './assets/css/themeMenu/iconfont.css';

// 阻止默认事件
import { GlobalTerminal } from './config/terminal';
new GlobalTerminal().init();

/**
 * 全局Vue扩展
 * eventExtend 事件扩展
 * libraryExtend 第三方库扩展
 * componentExtend 组件扩展
 * pluginExtend 插件扩展
*/
import {
    eventExtend,
    libraryExtend,
    componentExtend,
    pluginExtend
} from './extends/index.js';
eventExtend(Vue);
libraryExtend(Vue);
componentExtend(Vue);
pluginExtend(Vue);

/**
 * 全局指令
 *
 */
import './directive/removeAriaHidden/index.js';

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
